import { IExpose } from '@/UI/CastomTable/index.vue'
import { MessagesWebSocket } from '@/core/web-socket'
import { Area } from '@/pages/areas/types'
import { DocumentEntity } from '@/pages/documentation/types'
import { Ref } from 'vue'

export function changeTableRow<T extends Ref<IExpose<Area|DocumentEntity>>> (table: T, messages: MessagesWebSocket[]) {
  messages.map(mess => {
    if (table.value.items.findIndex(item => mess.type.includes(item.typeTable)) !== -1) {
      mess.type.includes('update_') ? updateTable(table, mess) : addNewRow(table, mess)
    }
  })
}

function updateTable<T extends Ref<IExpose<Area|DocumentEntity>>> (table: T, message: MessagesWebSocket) {
  const isThisTable = table.value.items.findIndex(item => message.type.includes(item.typeTable))
  const isRow = table.value.items.findIndex(item => message.data.id === item.id)

  if (isRow !== -1 && isThisTable !== -1) table.value.items.splice(isRow, 1, message.data)
}

function addNewRow<T extends Ref<IExpose<Area|DocumentEntity>>> (table: T, message: MessagesWebSocket) {
  const isRow = table.value.items.findIndex(item => message.data.id === item.id)

  if (isRow === -1 && message.data.id) table.value.items.push(message.data)
}