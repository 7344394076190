
import { PropType, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { IHeader, IParams } from './index.vue'
import Equals from './icons/Equals.vue'
import NotEqual from './icons/NotEqual.vue'
import Contains from './icons/Contains.vue'
import NotContain from './icons/NotContain.vue'
import More from './icons/More.vue'
import Less from './icons/Less.vue'
import Shaded from './icons/Shaded.vue'
import NotShaded from './icons/NotShaded.vue'
import Empty from './icons/Empty.vue'
import DatitimePicker from './DatitimePicker.vue'
import Discrepancies from './icons/Discrepancies.vue'
import NoDiscrepancies from './icons/NoDiscrepancies.vue'

export type TAdd4filterParams = 'eq' | 'not_eq' | 'contains' | 'not_contains' | 'more_than' | 'less_than' | 'boolean_shaded'
  | 'boolean_not_shaded' | 'boolean_empty' | 'date' | 'boolean_discrepancy' | 'boolean_no_discrepancy'

export const filters4textField: TAdd4filterParams[] = ['eq', 'not_eq', 'contains', 'not_contains']

export const filters4numberField: TAdd4filterParams[] = ['eq', 'not_eq', 'more_than', 'less_than']

export const filters4booleanField: TAdd4filterParams[] = ['boolean_shaded', 'boolean_not_shaded', 'boolean_empty']

export const filters4dateField: TAdd4filterParams[] = ['date']

export const filters4textAndBooleanField: TAdd4filterParams[] = ['eq', 'not_eq', 'contains', 'not_contains', 'boolean_discrepancy', 'boolean_no_discrepancy']

export interface IFilterTypeItem {
  // eslint-disable-next-line no-undef
  icon: JSX.Element
  text: string
  paramEnd: TAdd4filterParams
}

export default defineComponent({
  props: {
    headers: {
      type: Object as PropType<IHeader[]>,
      required: true
    },
    setFilterParams: {
      type: Function as PropType<(newVal: IParams) => void>,
      required: true
    }
  },
  setup (props) {
    const filterTypes: IFilterTypeItem[] = [
      { icon: <Equals/>, text: 'Равно', paramEnd: 'eq' },
      { icon: <NotEqual/>, text: 'Не равно', paramEnd: 'not_eq' },
      { icon: <Contains/>, text: 'Содержит', paramEnd: 'contains' },
      { icon: <NotContain/>, text: 'Не содержит', paramEnd: 'not_contains' },
      { icon: <More/>, text: 'Больше', paramEnd: 'more_than' },
      { icon: <Less/>, text: 'Меньше', paramEnd: 'less_than' },
      { icon: <Shaded/>, text: 'Все', paramEnd: 'boolean_shaded' },
      { icon: <NotShaded/>, text: 'Отмеченные', paramEnd: 'boolean_not_shaded' },
      { icon: <Empty/>, text: 'Не отмеченные', paramEnd: 'boolean_empty' },
      { icon: <Equals/>, text: 'Дата', paramEnd: 'date' },
      { icon: <Discrepancies/>, text: 'Расхождения', paramEnd: 'boolean_discrepancy' },
      { icon: <NoDiscrepancies/>, text: 'Нет расхождения', paramEnd: 'boolean_no_discrepancy' }
    ]

    function getFilterParamsKey (sortAndFilterKey: string, addParams: TAdd4filterParams) {
      if (addParams === 'date') return sortAndFilterKey
      return `${sortAndFilterKey}_${addParams}`
    }

    function getFilterTypes4field (headerFilterType?: TAdd4filterParams[]) {
      if (!headerFilterType?.length) return []
      return filterTypes.filter((filterType) => headerFilterType.includes(filterType.paramEnd))
    }

    const filtersArray = ref(props.headers.map((header) => {
      const filterTypes4field = getFilterTypes4field(header.filterTypes)

      return {
        selectedTypeFilter: { ...filterTypes4field[0] },
        filterTypes: filterTypes4field,
        isOpenMenu: false
      }
    }))

    const filterKeyValue = ref(props.headers.map((header) => {
      const paramEnd = getFilterTypes4field(header.filterTypes)[0]?.paramEnd
      return {
        key: getFilterParamsKey(header.sortAndFilterKey, paramEnd),
        value: ''
      } as { key: string, value: string | boolean }
    }))

    function isContainsBooleanAndDate (str: string) {
      return str.includes('boolean') || str === 'date'
    }

    watch(filterKeyValue, (newVal) => {
      const results: IParams = {}
      newVal.forEach((filter) => {
        let { key, value } = filter

        if (isContainsBooleanAndDate(key)) {
          /* Исключение добавочной части для параметров фильтрации
          у булевых полей и добавление им фиксированного значения */
          const keySpliting = key.split('_boolean')
          key = keySpliting[0]
          const paramEnd = `boolean${keySpliting.slice(1).join('_')}` as TAdd4filterParams

          if (paramEnd === 'boolean_not_shaded') value = true
          if (paramEnd === 'boolean_empty') value = false
          if (paramEnd === 'boolean_discrepancy') {
            key = keySpliting.join('_')
            value = true
          }
          if (paramEnd === 'boolean_no_discrepancy') {
            key = keySpliting[0] + paramEnd.split('boolean_no').join('_')
            value = false
          }
        }

        if (value === '' || value === null) {
          delete results[key]
          return
        }

        results[key] = value
      })

      props.setFilterParams(results)
    }, { deep: true })

    function openContextMenu (event: MouseEvent, index: number) {
      event.preventDefault()
      closeAllMenu()
      if (filtersArray.value[index].filterTypes.length < 2) return
      filtersArray.value[index].isOpenMenu = true
    }

    function setSelectedTypeFilter (index: number, filterType: IFilterTypeItem, sortAndFilterKey: string) {
      filtersArray.value[index].selectedTypeFilter = { ...filterType }
      filterKeyValue.value[index].key = getFilterParamsKey(sortAndFilterKey, filterType.paramEnd)
    }

    function closeAllMenu () {
      filtersArray.value.forEach((filter) => { filter.isOpenMenu = false })
    }

    onMounted(() => {
      window.addEventListener('click', closeAllMenu)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('click', closeAllMenu)
    })

    return () => (
      <tr class="filters">
        {props.headers.map((header, index) => (
          <td onContextmenu={(e) => openContextMenu(e, index)}>
            {!!filtersArray.value[index].filterTypes.length && <div class="filter-block">
              {filtersArray.value[index].selectedTypeFilter.icon}
              {!isContainsBooleanAndDate(filtersArray.value[index].selectedTypeFilter.paramEnd) &&
                <input type="text" v-model={filterKeyValue.value[index].value}/>
              }
              {filtersArray.value[index].selectedTypeFilter.paramEnd === 'date' &&
                <DatitimePicker
                  setValue={(newVal: string) => { filterKeyValue.value[index].value = newVal }}
                />
              }
            </div>}
            {filtersArray.value[index].isOpenMenu && <ul class="filter-context-menu">
              {filtersArray.value[index].filterTypes.map((filterType) => (
                <li
                  onClick={() => { setSelectedTypeFilter(index, filterType, header.sortAndFilterKey) }}
                >{filterType.icon}{filterType.text}</li>
              ))}
            </ul>}
          </td>
        ))}
      </tr>
    )
  }
})
